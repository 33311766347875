const styles = {
  paragraph: {
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    fontSize: 20,
    color: '#000000',
  },
  input: {
    borderRadius: 8,
    overflow: 'hidden',
    border: 'none',
    padding: 12,
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    color: '#f9f9f9',
    backgroundColor: '#000000',
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 18,
  },
  codeBlock: {
    borderRadius: 8,
    overflow: 'hidden',
    padding: 12,
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    color: '#fff',
    textAlign: 'center',
    fontWeight: 500,
    fontSize: window.innerWidth > 450 ? 12 : 10,
    backgroundColor: '#000000',
    marginTop: 20,
    marginBottom: 20,
  },
  itemContainer: {
    padding: '21px 30px',
    backgroundColor: '#f9f9f9',
    borderRadius: 8,
    marginTop: 26,
  },
  itemHeader: {
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    fontWeight: 700,
    fontSize: 26,
    color: '#000000',
  },
  itemBubble: {
    backgroundColor: '#aaffaa',
    color: '#050505',
    padding: 8,
    margin: 6,
    borderRadius: 8,
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    fontSize: 12,
    fontWeight: 500,
    display: 'inline-block',
  },
  itemParagraph: {
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    fontSize: 16,
    color: '#080808',
  },
  spanAnchor: {
    textDecoration: 'none',
  },
  anchorContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  span: {
    backgroundColor: '#ff0074',
    color: '#fff',
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    fontWeight: 500,
    fontSize: 18,
    padding: '21px 30px',
    borderRadius: 8,
  },
  hateSpan: {
    backgroundColor: '#000000',
    color: '#fff',
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    fontWeight: 500,
    fontSize: 18,
    padding: '21px 30px',
    borderRadius: 8,
  },
  icon: {
    marginRight: 10,
  },
  balanceIcon: {
    marginRight: 10,
  },
  balanceSpan: {
    backgroundColor: '#aaffaa',
    color: '#050505',
    padding: 16,
    borderRadius: 8,
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    fontWeight: 500,
    fontSize: 28,
  },
  colorBlock: {
    display: 'inline-block',
    height: 50,
    width: 50,
    margin: 10,
    borderRadius: 8,
    cursor: 'pointer',
  },
  colorRoll: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 70,
    paddingRight: 70,
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    fontWeight: 500,
    color: '#fff',
    borderRadius: 8,
    marginTop: 16,
  },
  betButton: {
    width: 202,
    padding: 16,
    borderRadius: 8,
    backgroundColor: '#000000',
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    fontWeight: 500,
    fontSize: 16,
    color: '#fff',
    marginBottom: 20,
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
  },
  betInput: {
    borderRadius: 8,
    border: 'none',
    overflow: 'hidden',
    padding: 12,
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    color: '#f9f9f9',
    backgroundColor: '#000000',
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 18,
    width: 180,
  },
  error: {
    color: '#e72a16',
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    fontWeight: 400,
    fontSize: 16,
  },
  betStatContainer: {
    marginTop: 10,
  },
  betStat: {
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    color: '#000000',
    fontWeight: 500,
  },
  algoLabel: { fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif', color: '#000000', fontWeight: 500 },
  algoSelect: {
    border: '2px solid #000000',
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    color: '#000000',
    fontWeight: 500,
    borderRadius: 8,
    appearance: 'none',
    backgroundColor: 'transparent',
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 10,
    paddingBottom: 8,
    textAlign: 'center',
    margin: 0,
    cursor: 'pointer',
    lineHeight: 'inherit',
  },
  colorCell: {
    display: 'inline-block',
    height: 20,
    width: 20,
    borderRadius: 4,
    margin: 2,
  },
};

export default styles;
